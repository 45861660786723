<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.pname{font-weight: bold; padding-left: 15px;}
.line{display:flex; flex-wrap: nowrap;}
.block td{background-color:#e6e6e6 !important}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:12px; color:#666; }
.el-cascader{width:170px;}
.keyword{width:200px;}
.channel .edit_dialog{height:auto !important; margin:15vh auto !important;}
.channel .edit_dialog .el-row:nth-of-type(n+2){margin-top:20px;}
.channel .edit_dialog .label{font-size:15px; color:#909399;}
</style>
<template>
    <div class="component channel">
        <!-- 编辑框 -->
        <el-dialog width="350px" custom-class="edit_dialog" :title="data_for_edit.id>0 ? '编辑分类' : '添加分类'" v-model="show_edit" :close-on-click-modal="false">
            <el-row :gutter="10">
                <el-col :span="7" class="label">所属分类</el-col>
                <el-col :span="17"><span class="pname" v-html="data_for_edit.prow ? data_for_edit.prow.name : '无'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">分类名称</el-col>
                <el-col :span="17">
                    <el-input v-model="data_for_edit.name"  placeholder="输入资讯分类名称" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <!-- <el-row :gutter="10" class="label" v-if="gd.me.role > 1">
                <el-col :span="7">可见区域</el-col>
                <el-col :span="17">
                    <el-cascader style="width:100%" v-model="data_for_edit.area" :props="cascader_config" size="small" ref="areas1" @change="this.$refs.areas1.togglePopperVisible()" placeholder="点击选择"></el-cascader>
                </el-col>
            </el-row> -->
            <template #footer>
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->
        <div class="title-panel">
            <el-row>
                <el-col :span="18">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">资讯分类</div><div class="descript">管理资讯分类，支持无限层级子类。</div></div>
                </el-col>
                <el-col :span="6" class="btn-col">
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加分类</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="table-box" id="table-box">
            <el-table ref="table" :max-height="table_height" :data="list" :stripe="true" row-key="id" lazy :load="lazy_load_chn" border :row-class-name="tableRowClassName">
                <el-table-column prop="name" label="分类名称" width="250"></el-table-column>
                <el-table-column prop="path_name" label="分类路径" width="300" :formatter="format_path"></el-table-column>
                <el-table-column label="排序" width="400" align="center">
                    <template #default="scope">
						<el-button size="small" plain icon="el-icon-upload2" @click="click_top(scope.row)">到顶</el-button>
						<el-button size="small" plain icon="el-icon-top" @click="click_up(scope.row)">上移</el-button>
						<el-button size="small" plain icon="el-icon-bottom" @click="click_down(scope.row)">下移</el-button>
						<el-button size="small" plain icon="el-icon-download" @click="click_bottom(scope.row)">到底</el-button>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" min-width="200" label="操作">
                    <template #default="scope">
                        <el-button v-if="scope.row.block==0" @click="click_block(scope.row)" type="text" size="small">停用</el-button>
                        <el-button v-else @click="click_block(scope.row)" type="text" size="small">启用</el-button>
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_add_child(scope.row)" type="text" size="small">添加子分类</el-button>
                        <el-button @click="click_del(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
</template>
<script>
import {inject} from 'vue'
export default {
  setup:function(){
      const gd = inject('gd');
      return {gd};
  },//setup
  data:function(){
    return {
        list:[],
        show_edit:false,
        data_for_edit:{},
        table_height:800,
        // cascader_config:{
        //     expandTrigger:'hover',
        //     checkStrictly:true,
        //     lazy: true,
        //     lazyLoad:(node, resolve)=>{
        //         console.log(node);
        //         this.axios.post("/api/area/list",{id:node.value || 0}).then(ret=>{
        //             if(!ret.ok){ this.$message.error(ret.msg); return;}
        //             let nodes = [];
        //             ret.data.map(item=>{
        //                 nodes.push({value:item.id, label:item.name, leaf:item.hasChildren<1});
        //             });
        //             resolve(nodes);
        //         });
        //     }
        // },//cascader_config
    }
  },//data end
  mounted:function(){
    this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
    this.init();
  },//mounted end
  computed:{

  },
  methods:{
    init: function(){
      this.load_list();
    },//init
    load_list:function(){
      this.axios.post("/api/article/chn_list",{pid:0}).then(ret=>{
        if(!ret.ok){this.$message.error(ret.msg); return;}
        this.list = ret.data;
      });
    },//load_list
    lazy_load_chn:function(row, rowNode, resolve){
		this.axios.post("/api/article/chn_list",{pid:row.id}).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			ret.data.map(item=>{item.prow = row;})
			resolve(ret.data);
		});
    },//lazy_load_chn
    tableRowClassName:function({row}){
        return row.block == 1 ? 'block' : '';
    },//tableRowClassName
    format_path:function(row){
        return row.path_name.replace(/,/g," / ");
    },//format_path
    click_top:function(row){
        this.axios.post("/api/article/chn_sort",{act:'top', id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_list();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_top
    click_up:function(row){
        this.axios.post("/api/article/chn_sort",{act:'up', id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_list();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_up
    click_down:function(row){
        this.axios.post("/api/article/chn_sort",{act:'down', id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_list();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_down
    click_bottom:function(row){
        this.axios.post("/api/article/chn_sort",{act:'bottom', id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_list();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_bottom
    click_block:function(row){
        this.axios.post("/api/article/chn_block",{id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){ this.load_list(); }else{
                if(this.$refs.table.store.states.treeData._value[row.pid].children.length > 1){
                    this.$refs.table.store.states.treeData._value[row.pid].loaded = false;
                    this.$refs.table.store.loadOrToggle(row.prow);
                }else{
                    delete this.$refs.table.store.states.treeData._value[row.pid];
                    this.$refs.table.store.loadOrToggle(row.prow);
                }
            }
        });
    },//click_del end
    click_del:function(row){
        this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.axios.post("/api/article/chn_del",{id:row.id}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                if(row.pid == 0){ this.load_list(); }else{
                    if(this.$refs.table.store.states.treeData._value[row.pid].children.length > 1){
                        this.$refs.table.store.states.treeData._value[row.pid].loaded = false;
                        this.$refs.table.store.loadOrToggle(row.prow);
                    }else{
                        delete this.$refs.table.store.states.treeData._value[row.pid];
                        this.$refs.table.store.loadOrToggle(row.prow);
                    }
                }
            });
        }).catch((e) => { console.error(e); });
    },//click_del end
    click_add:function(){
        this.show_edit = true;
        this.data_for_edit = {
            pid:0,
            prow:null,
            id:0,
            name:'',
        };
    },//click_add
    click_edit:function(row){
        this.show_edit = true;
        this.data_for_edit = JSON.parse(JSON.stringify(row));
    },//click_edit end
    click_add_child:function(row){
        this.show_edit = true;
        this.data_for_edit = {
            prow:row,
            pid:row.id,
            id:0,
            name:'',
        };
    },//click_add_child
    click_edit_done:function(){
		if(this.data_for_edit.name == '' ){ this.$message.error('请输入节点名称'); return; }
		let data = {id:this.data_for_edit.id, pid:this.data_for_edit.pid, name:this.data_for_edit.name};
		this.axios.post("/api/article/chn_edit",data).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
			this.show_edit = false;
			if(data.pid == 0){ this.load_list(); }else{
                if(this.$refs.table.store.states.treeData._value[data.pid]){
                    this.$refs.table.store.states.treeData._value[data.pid].loaded = false;
                    if(this.$refs.table.store.states.treeData._value[data.pid].expanded){
                        this.$refs.table.store.loadOrToggle(this.data_for_edit.prow);
                    }
                }else{
                    this.data_for_edit.prow.hasChildren = 1;
                    this.$refs.table.store.loadOrToggle(this.data_for_edit.prow);
                }
			}
		});
    },//click_edit_done
  },//methods end
};
</script>